






















import VueAsyncComputed from 'vue-async-computed';
import {Component, Vue} from 'vue-property-decorator';
import GenericFilterComponent from '@/components/search/GenericFilterComponent.vue';
import GenericFilter from '@/components/search/GenericFilter';
import SearchConfiguration from '@/components/search/SearchConfiguration';
import GenericFilterResults from '@/components/search/GenericFilterResults.vue';

Vue.use(VueAsyncComputed);

@Component({
    components: { GenericFilterComponent, GenericFilterResults }
})
export default class ItemSearch extends Vue {
    private loading = true;
    private initialized = true;
    private url = '';

    private qualities: {[key: number]: string} = {
        1: 'Ramshackle',
        2: 'Apprentice',
        3: 'Journeyman',
        4: 'Mastercraft',
        5: 'Ascendant'
    };

    private filters: {[key: string]: GenericFilter} = {
        serverId: {
            label: 'Server',
            field: 'serverId',
            type: 'autocomplete',
            autocomplete: {
                resource: 'server',
                idField: 'id',
                labelField: 'name'
            },
            valueType: 'number',
            selected: []
        },
        tribeId: {
            label: 'Tribe Id',
            field: 'inventory.tribeId',
            type: 'autocomplete',
            autocomplete: {
                resource: 'tribe',
                idField: 'tribeId',
                labelField: 'name'
            },
            valueType: 'number',
            selected: []
        },
        itemType: {
            label: 'Item Type',
            field: 'type',
            type: 'autocomplete',
            autocomplete: {
                resource: 'item',
                idField: 'type',
                labelField: 'type'
            },
            valueType: 'string',
            selected: []
        },
        inventoryType: {
            label: 'Inventory Type',
            field: 'inventory.className',
            type: 'autocomplete',
            autocomplete: {
                resource: 'item',
                idField: 'inventory.className',
                labelField: 'inventory.className',
            },
            valueType: 'string',
            selected: []
        },
        quality: {
            label: 'Quality',
            field: 'quality',
            type: 'select',
            options: this.qualities,
            valueType: 'string',
            selected: []
        }
    }

    private searchConfiguration: SearchConfiguration = {
        resource: 'item',
        filters: this.filters,
        columns: {
            server: {
                label: 'Server',
                field: 'serverId'
            },
            type: {
                label: 'Item Type',
                field: 'type'
            },
            inventory: {
                label: 'Inventory',
                field: 'inventory.className'
            },
            tribe: {
                label: 'Tribe',
                field: 'inventory.tribeId'
            },
            quality: {
                label: 'Quality',
                field: 'quality',
                displayValue: this.qualityName
            },
            quantity: {
                label: 'Quantity',
                field: 'quantity',
            }
        }
    }

    qualityName(value: number): string {
        if(this.qualities[value]) {
            return this.qualities[value];
        }
        return 'Primitive';
    }
}
